@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-white;
  display: flex;
  flex-direction: column;
}

#duolingo-icon {
  filter: brightness(0);
}

.active {
  @apply bg-gray-50;
}

.profile-pic {
  @apply h-64 w-48 rounded-lg mx-auto; /* Example of using @apply with Tailwind utilities */
}

.project-card {
  @apply snap-center rounded-xl p-5 mr-5 min-w-[300px] max-w-[300px] lg:min-w-[400px] lg:max-w-[400px];
}

#project-hero-1 {
  background-image: url('../public/images/databricks_rag2.png');
  background-size: auto 69%;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#project-hero-2 {
  background-image: url('../public/images/Avocado.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#project-hero-3 {
  background-image: url('../public/images/scroller1.webp');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#project-hero-4 {
  background-image: url('../public/images/Azure_f1.png');
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#project-hero-5 {
  background-image: url('../public/images/Azure_unity.png');
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.certification-card {
  @apply snap-center rounded-xl p-5 mr-5 min-w-[300px] max-w-[300px] lg:min-w-[400px] lg:max-w-[400px];
}

#certification-hero-1 {
  background-image: url('../public/images/AWS CCP.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#certification-hero-2 {
  background-image: url('../public/images/azure.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#certification-hero-3 {
  background-image: url('../public/images/power bi.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#certification-hero-4 {
  background-image: url('../public/images/databricks.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#certification-hero-5 {
  background-image: url('../public/images/deeplearning.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#certification-hero-6 {
  background-image: url('../public/images/udacity.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
